import { render, staticRenderFns } from "./SendToHelpdeskDialog.vue?vue&type=template&id=009d729a&scoped=true"
import script from "./SendToHelpdeskDialog.vue?vue&type=script&lang=ts"
export * from "./SendToHelpdeskDialog.vue?vue&type=script&lang=ts"
import style0 from "./SendToHelpdeskDialog.vue?vue&type=style&index=0&id=009d729a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "009d729a",
  null
  
)

export default component.exports